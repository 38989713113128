export default [
  {
    header: 'Suporte e Ajuda',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'API',
    icon: 'LayersIcon',
    resource: 'Auth',
    action: 'read',
    route: 'API'
  },
  {
    title: 'Tutoriais',
    icon: 'YoutubeIcon',
    resource: 'Auth',
    action: 'read',
    route: 'Tutoriais'
  },
  {
    title: 'FAQ',
    icon: 'HelpCircleIcon',
    resource: 'Auth',
    action: 'read',
    route: 'FAQ'
  },
  {
    title: 'Ticket',
    icon: 'LifeBuoyIcon',
    resource: 'Auth',
    action: 'read',
    route: 'Suporte'
  },
  {
    title: 'Grupos',
    icon: 'UsersIcon',
    resource: 'Auth',
    action: 'read',
    route: 'Grupos'
  }
]
