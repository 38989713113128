export default [
  {
    header: 'Minhas Compras',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Números Ativos',
    icon: 'PhoneCallIcon',
    route: 'Números Ativos',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Histórico de SMS',
    icon: 'ArchiveIcon',
    route: 'Histórico de SMS',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Meus Seguidores',
    icon: 'UserPlusIcon',
    route: 'Meus Seguidores',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Minhas Contas',
    icon: 'UsersIcon',
    route: 'Minhas Contas',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Minhas VPS e VPN',
    icon: 'ServerIcon',
    route: 'Minhas VPS',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Minhas Fotos',
    icon: 'CameraIcon',
    route: 'Minhas Fotos',
    resource: 'Auth',
    action: 'read'
  }
]
