export default [
  {
    header: 'Controle de Contas',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Controle Infinity',
    icon: 'InstagramIcon',
    route: 'Controle Infinity (Assinar)',
    resource: 'controle',
    action: 'buy'
  },
  {
    title: 'Controle Infinity',
    icon: 'InstagramIcon',
    children: [
      {
        title: 'Resumo',
        icon: 'HomeIcon',
        route: 'Controle Infinity (Minha Assinatura)',
        resource: 'controle',
        action: 'all'
      },
      {
        title: 'Gerenciar Contas',
        icon: 'InstagramIcon',
        route: 'Controle Infinity (Gerenciar Contas)',
        resource: 'controle',
        action: 'all'
      },
      {
        title: 'Gerenciar Ganhos',
        icon: 'ActivityIcon',
        route: 'Controle Infinity (Gerenciar Ganhos)',
        resource: 'controle',
        action: 'all'
      }
    ],
    resource: 'controle',
    action: 'all'
  }
]
