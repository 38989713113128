export default [
  {
    header: 'Serviços',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Comprar serviços',
    icon: 'ShoppingBagIcon',
    route: 'Comprar Serviços',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Pedido Em Massa',
    icon: '',
    route: 'Pedido Em Massa',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Comprar VPS e VPN',
    icon: 'ServerIcon',
    route: 'Comprar VPS',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'VerificadorInfinity',
    icon: 'TerminalIcon',
    route: 'VerificadorInfinity',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Transações',
    icon: 'DollarSignIcon',
    route: 'Transações',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Serviços',
    icon: 'ShoppingBagIcon',
    route: 'Serviços',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Estoque',
    icon: 'ArchiveIcon',
    route: 'Estoque',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Sorteios',
    icon: 'GiftIcon',
    route: 'Sorteios',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Indicações',
    icon: 'PercentIcon',
    route: 'Afiliados',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Conversor de Contas',
    icon: 'ClipboardIcon',
    route: 'Conversor de Contas',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Gerador de Emails',
    icon: 'MailIcon',
    route: 'Emails',
    resource: 'Auth',
    action: 'read'
  }
]
