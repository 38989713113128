export default [
  {
    title: 'Página Inicial',
    icon: 'HomeIcon',
    route: 'Dashboard',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Adicionar Saldo',
    icon: 'CreditCardIcon',
    route: 'Adicionar Saldo',
    resource: 'credits',
    action: 'read'
  },
  {
    title: 'Painel de Controle',
    icon: 'SlidersIcon',
    route: 'Dashboard (Admin)',
    children: [
      {
        title: 'Dashboard',
        route: 'Dashboard (Admin)',
        icon: 'DollarSignIcon',
        resource: 'users',
        action: 'read'
      },
      {
        title: 'PicPay',
        route: 'PicPay (Admin)',
        icon: 'DollarSignIcon',
        resource: 'users',
        action: 'read'
      },
      {
        title: 'Controle de Saldo',
        route: 'Controle de Saldo (Admin)',
        icon: 'DollarSignIcon',
        resource: 'users',
        action: 'read'
      },
      {
        title: 'Saldos de API',
        route: 'Saldos de API (Admin)',
        icon: 'DollarSignIcon',
        resource: 'users',
        action: 'read'
      },
      {
        title: 'Serviços',
        route: 'Serviços (Admin)',
        icon: 'ShoppingBagIcon',
        resource: 'services',
        action: 'read'
      },
      {
        title: 'Sorteios',
        route: 'Sorteios (Admin)',
        icon: 'GiftIcon',
        resource: 'users',
        action: 'read'
      },
      {
        title: 'Contas',
        route: 'Adicionar Contas (Admin)',
        icon: 'TwitterIcon',
        resource: 'accounts',
        action: 'read'
      },
      {
        title: 'Contas VPS',
        route: 'Adicionar Contas VPS (Admin)',
        icon: 'ServerIcon',
        resource: 'accounts',
        action: 'read'
      },
      {
        title: 'Buscar VPS',
        route: 'Buscar Contas VPS (Admin)',
        icon: 'ServerIcon',
        resource: 'vps_accounts',
        action: 'read'
      },
      {
        title: 'Pack de Fotos',
        route: 'Adicionar Pack de Fotos (Admin)',
        icon: 'CameraIcon',
        resource: 'accounts',
        action: 'read'
      },
      {
        title: 'VerificadorInfinity',
        route: 'VerificadorInfinity (Admin)',
        icon: 'TerminalIcon',
        resource: 'verificador_infinity_subscription',
        action: 'read'
      },
      {
        title: 'Usuários',
        route: 'Usuários (Admin)',
        icon: 'UsersIcon',
        resource: 'users',
        action: 'read'
      },
      {
        title: 'Banimentos',
        route: 'Banimentos (Admin)',
        icon: 'UsersIcon',
        resource: 'users',
        action: 'read'
      },
      {
        title: 'Pagamentos',
        route: 'Pagamentos (Admin)',
        icon: 'DollarSignIcon',
        resource: 'users',
        action: 'read'
      },
      {
        title: 'Afiliados (Saque)',
        route: 'Afiliados (Saque) (Admin)',
        icon: 'UsersIcon',
        resource: 'users',
        action: 'read'
      },
      {
        title: 'Notícias',
        route: 'Noticias (Admin)',
        icon: 'ShoppingBagIcon',
        resource: 'services',
        action: 'read'
      },
      {
        title: 'Fornecedores',
        route: 'Fornecedores (Admin)',
        icon: 'PenToolIcon',
        resource: 'services',
        action: 'read'
      },
      {
        title: 'Logs',
        route: 'Logs (Admin)',
        icon: '',
        resource: 'services',
        action: 'read'
      }
    ]
  }
]
