export default [
  // {
  //   header: 'Outros'
  // },
  // {
  //   title: 'Sorteios',
  //   icon: 'GiftIcon'
  // },
  // {
  //   title: 'Estoque',
  //   icon: 'ArchiveIcon'
  // },
  // {
  //   title: 'Transações',
  //   icon: 'DollarSignIcon'
  // }
]
